import React from 'react';
import moment from 'moment';
import logo from '../assets/img/RSUMPO.png';
import 'moment/locale/id';

const Header = () => {
  return (
    <div className='text-center mb-3 d-block'>
      <h6 className='text-[23px] mb-0 font-medium uppercase'>Antrean Klinik</h6>
      <h2 className='text-4xl font-semibold flex items-center justify-center uppercase'>
        <img src={logo} alt='img-logo' className='w-11 mr-3 md:block hidden' />
        RSU Muhammadiyah Ponorogo
      </h2>
      <h3 className='font-medium text-xl uppercase'>
        {moment(new Date()).format('dddd, Do MMM YYYY')}
      </h3>
    </div>
  );
};

export default Header;
