import axios from 'axios';
import React, { useEffect, useState } from 'react';
import CardComponent from './components/CardComponent';
import LoadingSpeaner from './components/LoadingSpinner';
import Header from './components/Header';

const App = () => {
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(false);

  useEffect(() => {
    getAntrean();
    const interval = setInterval(() => {
      getAntrean();
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  const getAntrean = async () => {
    setloading(true);
    try {
      const { data } = await axios.get(
        'https://wsdisplay.rsumponorogo.com/api/antrean-klinik'
      );
      setdata(data);
    } catch (error) {
      console.error(error.message);
    }
    setloading(false);
  };

  const resultData = (
    <div className='p-3'>
      <Header />
      <div className='grid lg:grid-cols-4 md:grid-cols-2 xs:grid-cols-1 gap-4'>
        {data.map((item, index) => (
          <CardComponent data={item} key={index} />
        ))}
      </div>
    </div>
  );

  return (
    <React.Fragment>{loading ? <LoadingSpeaner /> : resultData}</React.Fragment>
  );
};

export default App;
