import React from 'react';

const CardComponent = (props) => {
  return (
    <div className='shadow-lg bg-white rounded-lg border overflow-hidden border-gray-200'>
      <div className='border-b py-4 px-4 bg-[#05771a]'>
        <h2 className='text-xl text-center text-white font-semibold mb-0'>
          {props.data.ruangan}
        </h2>
      </div>
      <div className='py-4 px-4'>
        <div className='flex flex-row mb-2'>
          <div className='basis-1/4 font-medium'>Dokter</div>
          <div className='basis-3/4 font-medium text-right'>
            {props.data.Dokter}
          </div>
        </div>
        <div className='flex flex-row mb-2'>
          <div className='basis-1/4 font-medium'>Jumlah</div>
          <div className='basis-3/4 font-medium text-right'>
            {props.data.jumlahantrian}
          </div>
        </div>
        <div className='flex flex-row'>
          <div className='basis-1/4 font-medium'>Terpanggil</div>
          <div className='basis-3/4 font-medium text-right'>
            {props.data.panggil}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardComponent;
