import React from 'react';

const LoadingSpeaner = () => {
  return (
    <div className='fixed top-0 left-0 right-0 bottom-0 h-screen w-full z-50 overflow-hidden flex flex-col items-center justify-center'>
      <div className='loader ease-linear rounded-full border-4 border-top-4 border-gray-200 h-12 w-12 mb-4'></div>
      <h2 className='text-center text-white text-xl font-semibold'>
        Loading ...
      </h2>
      <p className='text-center w-1/3 text-slate-800'>
        Mohon di tunggu, kami sedang menyiapkan data antrean
      </p>
    </div>
  );
};

export default LoadingSpeaner;
